import { Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./pages/about/about";
import Blog from "./pages/blog/blog";
import Contact from "./pages/contact/contact";
import DetailBlog from "./pages/detailBlog/detailBlog";
import Feature from "./pages/feature/feature";
import ForgetPassword from "./pages/forgetPassword/forgetPassword";
import Home from "./pages/home/home";
import Subscribe from "./pages/subscribe/subscribe";
import Login from "./pages/login/login";
import NotFound from "./pages/notFound/notFound";
import Paket from "./pages/paket/paket";
import Service from "./pages/service/service";
import Signup from "./pages/signup/signup";
import SuccessOrder from "./pages/successOrder/successOrder";
import Promo from "./pages/promo/promo";
import Verification from "./pages/verification/verification";
import SuccessUpgrade from "./pages/successUpgrade/successUpgrade";
import CheckInvoice from "./pages/checkInvoice/checkInvoice";
import FinishInvoice from "./pages/finishInvoice/finishInvoice";
import UnfinishInvoice from "./pages/unfinishInvoice/unfinishInvoice";
import ErrorPayment from "./pages/errorPayment/errorPayment";
import ValidateInvoice from "./pages/validateInvoice/validateInvoice";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import HelpCenter from "./pages/helpCenter/helpCenter";
import FAQ from "./pages/faq/faq";
import Coverage from "./pages/coverage/coverage";

function App() {
  return (
    <div className="App">
      <GoogleReCaptchaProvider reCaptchaKey="6Ldxxw4bAAAAAHVBc271fWbPo5tLPiq1hlslR17a">
        <Routes>
          <Route path="/" index element={<Home />} />
          {/* <Route path="/about" element={<About />} /> */}
          <Route path="/faq" element={<FAQ />} />
          {/* <Route path="/service" element={<Service />} /> */}
          <Route path="/feature" element={<Feature />} />
          <Route path="/paket" element={<Paket />} />
          {/* <Route path="/subscribe" element={<Subscribe />} /> */}
          {/* <Route path="/blog" element={<Blog />} /> */}
          {/* <Route path="/blog/:id" element={<DetailBlog />} /> */}
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/signup" element={<Signup />} /> */}
          {/* <Route path="/forget-password" element={<ForgetPassword />} /> */}
          <Route path="/upgrade" element={<Promo />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/success-upgrade" element={<SuccessUpgrade />} />
          <Route path="/cek-tagihan" element={<CheckInvoice />} />
          <Route path="/finish-payment" element={<FinishInvoice />} />
          <Route path="/unfinish-payment" element={<UnfinishInvoice />} />
          <Route path="/error-payment" element={<ErrorPayment />} />
          <Route path="/integrity" element={<ValidateInvoice />} />
          <Route path="/help-center" element={<HelpCenter />} />
          <Route path="/coverage" element={<Coverage />} />
          {/* <Route path="/subscribe/success" element={<SuccessOrder />} /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </GoogleReCaptchaProvider>
    </div>
  );
}

export default App;
