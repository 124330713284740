import dukodu5 from "../../assets/img/dukodu-mbps-5.png";
import dukodu10 from "../../assets/img/dukodu-mbps-10.webp";
import dukodu15 from "../../assets/img/dukodu-mbps-15.webp";
import dukodu20 from "../../assets/img/dukodu-mbps-20.webp";
import dukodu30 from "../../assets/img/dukodu-mbps-30.webp";
import dukodu50 from "../../assets/img/dukodu-mbps-50.webp";
import dukodu60 from "../../assets/img/dukodu-mbps-60.webp";
import dukodu70 from "../../assets/img/dukodu-mbps-70.webp";
import dukodu100 from "../../assets/img/dukodu-mbps-100.webp";

// soho
import soho100 from "../../assets/img/Dukodu-Suho-100.webp";
import soho150 from "../../assets/img/Dukodu-Suho-150.webp";
import soho200 from "../../assets/img/Dukodu-Suho-200.webp";
import soho300 from "../../assets/img/Dukodu-Suho-300.webp";
import soho500 from "../../assets/img/Dukodu-Suho-500.webp";

const ListProduct: any = [
  {
    area: "Promo",
    type_product: [
      {
        name: "Internet Only",
        desc_product: [
          {
            name: "Dukodu Basic",
            image: dukodu30,
            price: "Rp 175.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-6 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu Pro",
            image: dukodu50,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 6-7 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu Ultra",
            image: dukodu100,
            price: "Rp 250.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 7-8 Perangkat" },
            ],
            addons: [],
          },
        ],
      },
    ],
  },
  {
    area: "Depok",
    type_product: [
      {
        name: "Internet Only",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 175.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 299.000,-",

            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 70",
            image: dukodu70,
            price: "Rp 349.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [],
          },
        ],
      },
      {
        name: "Internet + 1 Wifi Hub",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 175.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 324.000,-",

            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "1 Wifi Hub",
              },
            ],
          },
          {
            name: "Dukodu HOME 70",
            image: dukodu70,
            price: "Rp 374.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [
              {
                name: "1 Wifi Hub",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + TV",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 175.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 299.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 399.000,-",

            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu HOME 70",
            image: dukodu70,
            price: "Rp 449.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + IP Phone",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 175.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 299.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 399.000,-",

            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 70",
            image: dukodu70,
            price: "Rp 449.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + IP TV + IP Phone",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 175.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 399.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 524.000,-",

            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 70",
            image: dukodu70,
            price: "Rp 574.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    area: "Bogor Barat",
    type_product: [
      {
        name: "Internet Only",
        desc_product: [
          // {
          //   name: "Dukodu HOME 5",
          //   image: dukodu5,
          //   price: "Rp 150.000,-",
          //   feature: [
          //     { name: "Kuota Tak Terbatas" },
          //     { name: "Wifi Modem" },
          //     { name: "Gratis Instalasi" },
          //     { name: "Up To 2 Perangkat" },
          //   ],
          //   addons: [],
          // },
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 175.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 15",
            image: dukodu15,
            price: "Rp 224.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 2-4 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 249.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 299.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 349.000,-",

            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 70",
            image: dukodu70,
            price: "Rp 599.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [],
          },
        ],
      },
      {
        name: "Internet + 1 Wifi Hub",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 175.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 15",
            image: dukodu15,
            price: "Rp 224.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 2-4 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 249.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 324.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 374.000,-",

            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
            ],
          },
          {
            name: "Dukodu HOME 70",
            image: dukodu70,
            price: "Rp 624.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + TV",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 175.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 15",
            image: dukodu15,
            price: "Rp 324.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 2-4 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 349.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 399.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 449.000,-",

            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu HOME 70",
            image: dukodu70,
            price: "Rp 699.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + IP Phone",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 175.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 15",
            image: dukodu15,
            price: "Rp 324.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 2-4 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 349.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 399.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 449.000,-",

            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 70",
            image: dukodu70,
            price: "Rp 699.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + IP TV + IP Phone",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 175.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 15",
            image: dukodu15,
            price: "Rp 424.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 2-4 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 449.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 524.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 574.000,-",

            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 70",
            image: dukodu70,
            price: "Rp 824.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    area: "Azzikra Bogor",
    type_product: [
      {
        name: "Internet Only",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 249.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 299.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 349.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [],
          },
        ],
      },
      {
        name: "Internet + 1 Wifi Hub",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 249.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 324.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 374.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + TV",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 349.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 399.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 449.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + IP Phone",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 349.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 399.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 449.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + IP TV + IP Phone",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 449.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 524.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 574.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    area: "Ciawi Bogor",
    type_product: [
      {
        name: "Internet Only",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 249.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 299.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 349.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 70",
            image: dukodu70,
            price: "Rp 599.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [],
          },
        ],
      },
      {
        name: "Internet + 1 Wifi Hub",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 249.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 324.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 374.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
            ],
          },
          {
            name: "Dukodu HOME 70",
            image: dukodu70,
            price: "Rp 624.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + TV",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 349.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 399.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 449.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu HOME 70",
            image: dukodu70,
            price: "Rp 699.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + IP Phone",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 349.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 399.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 449.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 70",
            image: dukodu70,
            price: "Rp 699.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + IP TV + IP Phone",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 449.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 524.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 574.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 70",
            image: dukodu70,
            price: "Rp 824.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Biaya Instalasi Rp 50.000,-" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },

          // {
          //   name: "Dukodu HOME 100",
          //   image: dukodu100,
          //   price: "Rp 824.000,-",
          //   feature: [
          //     { name: "Kuota Tak Terbatas" },
          //     { name: "Wifi Modem" },
          //     { name: "Gratis Instalasi" },
          //     { name: "Up To 15-17 Perangkat" },
          //   ],
          //   addons: [
          //     {
          //       name: "1 Hub",
          //     },
          //     {
          //       name: "IP TV",
          //     },
          //     {
          //       name: "IP Phone",
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
  {
    area: "Plaza Pondok Gede",
    type_product: [
      {
        name: "Internet Only",
        desc_product: [
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 275.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 60",
            image: dukodu60,
            price: "Rp 525.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 12-13 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 100",
            image: dukodu100,
            price: "Rp 600.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 15-17 Perangkat" },
            ],
            addons: [],
          },
        ],
      },
      {
        name: "Internet + 1 Wifi Hub",
        desc_product: [
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 300.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
            ],
          },
          {
            name: "Dukodu HOME 60",
            image: dukodu60,
            price: "Rp 550.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 12-13 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
            ],
          },
          {
            name: "Dukodu HOME 100",
            image: dukodu100,
            price: "Rp 625.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 15-17 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + TV",
        desc_product: [
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 375.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu HOME 60",
            image: dukodu60,
            price: "Rp 625.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 12-13 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu HOME 100",
            image: dukodu100,
            price: "Rp 700.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 15-17 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + IP Phone",
        desc_product: [
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 375.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 60",
            image: dukodu60,
            price: "Rp 625.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 12-13 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 100",
            image: dukodu100,
            price: "Rp 700.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 15-17 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + IP TV + IP Phone",
        desc_product: [
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 500.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 60",
            image: dukodu60,
            price: "Rp 750.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 12-13 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 100",
            image: dukodu100,
            price: "Rp 825.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 15-17 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    area: "Kalimantan Barat",
    type_product: [
      {
        name: "Internet Only",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 249.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 299.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 399.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 100",
            image: dukodu100,
            price: "Rp 599.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 15-17 Perangkat" },
            ],
            addons: [],
          },
        ],
      },
      {
        name: "Internet + 1 Wifi Hub",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 249.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 324.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 424.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
            ],
          },
          {
            name: "Dukodu HOME 100",
            image: dukodu100,
            price: "Rp 624.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 15-17 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + TV",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 349.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 399.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 499.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu HOME 100",
            image: dukodu100,
            price: "Rp 699.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 15-17 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + IP Phone",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 349.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 399.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 499.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 100",
            image: dukodu100,
            price: "Rp 699.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 15-17 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + IP TV + IP Phone",
        desc_product: [
          {
            name: "Dukodu HOME 10",
            image: dukodu10,
            price: "Rp 199.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu HOME 20",
            image: dukodu20,
            price: "Rp 449.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 30",
            image: dukodu30,
            price: "Rp 524.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-7 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 50",
            image: dukodu50,
            price: "Rp 624.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu HOME 100",
            image: dukodu100,
            price: "Rp 824.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 15-17 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
        ],
      },
    ],
  },
];

const ListProductSoho: any = [
  {
    area: "Promo",
    type_product: [
      {
        name: "Internet Only",
        desc_product: [
          {
            name: "Promo Dukodu SOHO 150",
            image: soho150,
            price: "Rp 299.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Promo Dukodu SOHO 300",
            image: soho300,
            price: "Rp 349.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Promo Dukodu SOHO 500",
            image: soho500,
            price: "Rp 499.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [],
          },
        ],
      },
    ],
  },
  {
    area: "Depok",
    type_product: [
      {
        name: "Internet Only",
        desc_product: [
          {
            name: "Dukodu SOHO 100",
            image: soho100,
            price: "Rp 370.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu SOHO 200",
            image: soho200,
            price: "Rp 570.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 5-8 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu SOHO 300",
            image: soho300,
            price: "Rp 770.000,-",

            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [],
          },
          {
            name: "Dukodu SOHO 500",
            image: soho500,
            price: "Rp 950.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [],
          },
        ],
      },
      {
        name: "Internet + 1 Wifi Hub",
        desc_product: [
          {
            name: "Dukodu SOHO 100",
            image: soho100,
            price: "Rp 410.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [
              {
                name: "1 Wifi Hub",
              },
            ],
          },
          {
            name: "Dukodu SOHO 200",
            image: soho200,
            price: "Rp 610.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "1 Wifi Hub",
              },
            ],
          },
          {
            name: "Dukodu SOHO 300",
            image: soho300,
            price: "Rp 810.000,-",

            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "1 Wifi Hub",
              },
            ],
          },
          {
            name: "Dukodu SOHO 500",
            image: soho500,
            price: "Rp 990.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [
              {
                name: "1 Wifi Hub",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + TV",
        desc_product: [
          {
            name: "Dukodu SOHO 100",
            image: soho100,
            price: "Rp 470.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu SOHO 200",
            image: soho200,
            price: "Rp 670.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu SOHO 300",
            image: soho300,
            price: "Rp 870.000,-",

            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
          {
            name: "Dukodu SOHO 500",
            image: soho500,
            price: "Rp 1.050.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [
              {
                name: "IP TV",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + IP Phone",
        desc_product: [
          {
            name: "Dukodu SOHO 100",
            image: soho100,
            price: "Rp 470.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu SOHO 200",
            image: soho200,
            price: "Rp 670.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu SOHO 300",
            image: soho300,
            price: "Rp 870.000,-",

            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu SOHO 500",
            image: soho500,
            price: "Rp 1.050.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [
              {
                name: "IP Phone",
              },
            ],
          },
        ],
      },
      {
        name: "Internet + IP TV + IP Phone",
        desc_product: [
          {
            name: "Dukodu SOHO 100",
            image: soho100,
            price: "Rp 610.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 1-3 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu SOHO 200",
            image: soho200,
            price: "Rp 810.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 3-5 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu SOHO 300",
            image: soho300,
            price: "Rp 1.010.000,-",

            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 8-12 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
          {
            name: "Dukodu SOHO 500",
            image: soho500,
            price: "Rp 1.190.000,-",
            feature: [
              { name: "Kuota Tak Terbatas" },
              { name: "Wifi Modem" },
              { name: "Gratis Instalasi" },
              { name: "Up To 13-15 Perangkat" },
            ],
            addons: [
              {
                name: "1 Hub",
              },
              {
                name: "IP TV",
              },
              {
                name: "IP Phone",
              },
            ],
          },
        ],
      },
    ],
  },
];

export { ListProduct, ListProductSoho };
