import { latLng, Marker as MarkLeaflet, icon } from "leaflet";
import { useRef } from "react";
import { Helmet } from "react-helmet";
import { MapContainer, Popup, Marker, TileLayer } from "react-leaflet";
import marker from "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";

import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { useNavigate } from "react-router-dom";

let DefaultIcon = icon({
  iconUrl: marker,
  shadowUrl: iconShadow,
});

MarkLeaflet.prototype.options.icon = DefaultIcon;

const Coverage = () => {
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const center = latLng(-0.62796, 113.860366);
  const markerOption = [
    {
      position: latLng(-6.357853, 106.832856),
      title: "POP DEPOK",
    },
    {
      position: latLng(-6.643506, 106.684753),
      title: "POP GUNUNG BUNDER",
    },
    {
      position: latLng(-6.679106, 106.854778),
      title: "POP CIAWI",
    },
    {
      position: latLng(-6.277706, 107.087661),
      title: "POP CIKARANG",
    },
    {
      position: latLng(-6.174192, 106.299325),
      title: "POP SERANG (MULIA GADING KENCANA)",
    },
    {
      position: latLng(-6.284072, 106.911839),
      title: "POP PLAZA PONDOK GEDE",
    },
    {
      position: latLng(-6.556739, 106.842367),
      title: "POP AZZIKRA",
    },
    {
      position: latLng(-6.737628, 108.518861),
      title: "POP CIREBON",
    },
    {
      position: latLng(-2.996419, 120.197219),
      title: "POP PALOPO",
    },
    {
      position: latLng(-0.052694, 109.344997),
      title: "POP PONTIANAK",
    },
    {
      position: latLng(0.374311, 108.946311),
      title: "POP MEMPAWAH",
    },
    {
      position: latLng(0.902025, 108.970406),
      title: "POP SINGKAWANG",
    },
    {
      position: latLng(1.360803, 109.302172),
      title: "POP SAMBAS",
    },
    {
      position: latLng(1.606061, 109.674628),
      title: "POP ARUK",
    },
    {
      position: latLng(0.571144, 108.928353),
      title: "POP SUI DURI",
    },
    {
      position: latLng(1.178322, 109.052347),
      title: "POP SEMPARUK",
    },
    {
      position: latLng(1.542306, 109.359658),
      title: "POP GALING",
    },
    {
      position: latLng(1.561306, 109.420431),
      title: "POP SIJANG",
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dukodu - Coverage</title>
        <meta name="mobile-web-app-capable" content="yes"></meta>
      </Helmet>

      <div>
        <div className="py-3 text-center position-relative">
          <div
            style={{
              position: "absolute",
              left: "10px",
              top: "25px",
              cursor: "pointer",
            }}
            onClick={() => navigate(-1)}
          >
            Go Back
          </div>
          <div className="fs-4 fw-semibold">Daerah Cakupan Area</div>
        </div>

        <div id="map">
          <MapContainer
            center={center}
            zoom={6}
            scrollWheelZoom={false}
            ref={mapRef}
            style={{ height: "90vh", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {markerOption.map((datum, index) => (
              <Marker position={datum.position} key={index}>
                <Popup>{datum.title}</Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
    </>
  );
};

export default Coverage;
